/* eslint-disable import/extensions */
/* eslint-disable import/first */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/order */
/* eslint-disable max-len */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-console */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    useContext,
    useState,
    useEffect,
    useCallback,
    Suspense
} from 'react';
import { Switch, Route } from 'react-router-dom';
import _ from 'lodash';
// Imported First to allow Jutro styles to be overridden
import './App.scss';
import { setComponentMapOverrides } from '@jutro/uiconfig';
import {
    Main,
    Icon,
    Chevron,
    ModalNextProvider
} from '@jutro/components';
import { Table, TableColumn } from '@jutro/datatable';
import { TranslatorContext, LocaleService } from '@jutro/locale';
import { NavRoutes } from '@jutro/router';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';

import {
    DynamicRoute, GrantedAuthorities, withAuthContextProvider, useAuthentication
} from 'gw-digital-auth-react';
import vmTranslator, { messages as commonMessages } from 'gw-platform-translations';
import { AccurateBreakpointPropagation } from 'gw-jutro-adapters-react';
import { ViewModelServiceFactory } from 'gw-portals-viewmodel-js';
import { LoginCapabilityComponentMap, LoginCapabilityComponents } from 'gw-portals-login-react';
import { UsersProfile } from 'gw-capability-profileinfo-react';

import { DependencyProvider } from 'gw-portals-dependency-react';

import { ViewModelServiceContext } from 'gw-portals-viewmodel-react';
import {
    platformComponentMap,
    platformComponents,
    ImageComponent,
} from 'gw-components-platform-react';
import { ErrorBoundary } from 'gw-portals-error-react';

// import { filterCapabilityRoutes } from 'gw-portals-config-js';

// ospcchange add entrypage component for localhost testing, pending investigation for dev/sit deployment
// import custom component EntryPage
import EntryPage from '../pages/EntryPage/EntryPage';

import { routes } from './App.routes.metadata.json5';
// const custom component LandingPage
const LandingPage = React.lazy(() => import('../pages/LandingPage/LandingPage'));
// const custom message file for App component.
import messages from './App.messages';
// imposrt custom component SessionTimeoutModal
const SessionTimeoutModal = React.lazy(() => import('../wsib/common/session-timeout-modal/session-timeout-modal'));
// imposrt custom component SessionTimeoutService
import SessionTimeoutService from '../wsib/services/SessionTimeoutService';
// const custom component ClaimSummaryHome
const ClaimSummaryHome = React.lazy(() => import('../wsib/claim-dashboard/pages/claim-summary-home/claim-summary-home'));
// const {custom component SessionExpiredHome
import SessionExpiredHome from '../wsib/common/pages/session-expired-home';

const Document = React.lazy(() => import('../wsib/claim-details/document/page/document'));
const DocumentHistory = React.lazy(() => import('../wsib/claim-details/document/page/documentHistoryPage'));
const DirectDeposit = React.lazy(() => import('../wsib/claim-dashboard/pages/direct-deposit-home/direct-deposit-home'));
const ReportOverview = React.lazy(() => import('../wsib/claim-dashboard/report-overview/report-overview'));
// const {custom component Header
const Header = React.lazy(() => import('../wsib/common/header/header'));
// const {custom= React.lazy(() => import(onent Footer
const Footer = React.lazy(() => import('../wsib/common/footer/footer'));
// const {custom component LeftNav
const LeftNav = React.lazy(() => import('../wsib/common/left-nav/left-nav'));
// const {custom component ClaimOverviewHome
const ClaimOverviewHome = React.lazy(() => import('../wsib/claim-details/claim-overview/page/claim-overview-home'));
// const {custom component BenefitPaymentHome
const BenefitPaymentHome = React.lazy(() => import('../wsib/claim-details/benefit-payment/page/benefit-payment-home'));
const ClaimEnrollmentHome = React.lazy(() => import('../wsib/claim-dashboard/pages/claim-enrollment-home/claim-enrollment-home'));
const EnrollmentConfirmation = React.lazy(() => import('../wsib/claim-dashboard/pages/enrollment-confirmation-home/enrollment-confirmation-home'));
// const {custom loader coponent MakePaymentPage
import { LoaderComponentComponentMap, LoaderComponentComponents } from '../wsib/common/loader/page/index';
// const {custom component ClaimTombstone
const ClaimTombstone = React.lazy(() => import('../wsib/common/claim-tombstone/claim-tombstone'));
// const {custom component Logout
const Logout = React.lazy(() => import('../wsib/common/logout/logout'));
// const {UtilService
import UtilService from '../wsib/services/UtilService';
// const {custom component HelpfulLinks
const HelpfulLink = React.lazy(() => import('../wsib/common/helpful-links/helpful-links'));
// const {custom component HealthCareHome
const HealthCareHome = React.lazy(() => import('../wsib/claim-details/healthcare-benefits/page/healthcare-home'));
// const {custom component appeals
const AppealsHome = React.lazy(() => import('../wsib/claim-details/appeals/page/appeals-home'));
// import custom component NonHealthCareHome
const NonHealthCareHome = React.lazy(() => import('../wsib/claim-details/non-healthcare-benefits/page/non-healthcare-benefits-home'));
// const {custom component Login
import Login from '../wsib/login/login';
// const {custom component ClaimIntake
const ClaimIntake = React.lazy(() => import('../wsib/claim-intake/pages/claim-intake'));
// const {custom component MailHome
const MailHome = React.lazy(() => import('../wsib/claim-details/mail/page/mail-home'));
// const {custom component Send Message
const SendMessage = React.lazy(() => import('../wsib/claim-details/messaging/send-message/send-message'));
// const {custom context AppContext
import AppContext from '../wsib/app-context/app-context';
// const {custom component MailDetail
const MailDetail = React.lazy(() => import('../wsib/claim-details/mail/mail-detail/mail-detail'));
const SuccessMessage = React.lazy(() => import('../wsib/claim-details/mail/mail-success/mail-success'));
// const {IdpCallbackComponent to handle b2c error
const IdpCallbackComponent = React.lazy(() => import('../wsib/common/IdpCallbackComponent/IdpCallbackComponent'));
const unlockDirectDeposit = React.lazy(() => import('../wsib/claim-dashboard/direct-deposit/unlock-direct-deposit/unlock-direct-deposit'));
const noPinDirectdeposit = React.lazy(() => import('../wsib/claim-dashboard/direct-deposit/no-pin-direct-deposit/no-pin-direct-deposit'));
const IdpSystemErrorHome = React.lazy(() => import('../wsib/common/pages/idp-system-error-home'));

const SystemErrorHome = React.lazy(() => import('../wsib/common/pages/system-error-home'));
const UnsupportedBrowserHome = React.lazy(() => import('../wsib/common/pages/unsupported-browser-home'));
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../wsib/common/application-insight/application-insight.jsx';
import {
    GoogleReCaptchaProvider
} from 'react-google-recaptcha-v3';

const componentMap = {
    document: Document,
    documentHistory: DocumentHistory,
    DirectDeposit: DirectDeposit,
    unlockDirectDeposit: unlockDirectDeposit,
    noPinDirectdeposit: noPinDirectdeposit,
    reportOverview: ReportOverview,
    sessionTimeout: SessionTimeoutModal,
    claimSummaryHome: ClaimSummaryHome,
    claimOverviewHome: ClaimOverviewHome,
    claimEnrollmentHome: ClaimEnrollmentHome,
    benefitPaymentHome: BenefitPaymentHome,
    helpfulLink: HelpfulLink,
    healthCareHome: HealthCareHome,
    nonHealthCareHome: NonHealthCareHome,
    appealsHome: AppealsHome,
    mailHome: MailHome,
    landingpage: LandingPage,
    usersprofile: UsersProfile,
    sendMessage: SendMessage,
    successMessage: SuccessMessage,
    enrollmentConfirmation: EnrollmentConfirmation,
    mailDetail: MailDetail,
    claimIntake: ClaimIntake
};

// eslint-disable-next-line no-unused-vars
const shouldRouteToSignUp = (authorities) => {
    /* ospcchange: todo investigate the below osbc code and see whether it is applicable to ospc logic
    const accountAuthorities = authorities.filter((authority) => authority.authorityType === 'ACCOUNT');
    if (accountAuthorities.length === 0) {
        window.location.href = UtilService.businessLink(LocaleService.getCurrentLanguage());
    }
    */
    UtilService.previousRole = UtilService.getUserRole();
    UtilService.previousRoleArr = document.getElementsByTagName('html')[0].getAttribute('accesToken');
    return null;
};

setComponentMapOverrides(
    {
        ...platformComponentMap,
        ...LoginCapabilityComponentMap,
        ...LoaderComponentComponentMap,
        Icon: { component: 'Icon' },
        Table: { component: 'Table' },
        TableColumn: { component: 'TableColumn' },
        Chevron: { component: 'Chevron' },
        img: { component: 'img' },
    },
    {
        ...platformComponents,
        ...LoginCapabilityComponents,
        ...LoaderComponentComponents,
        Icon,
        Table,
        TableColumn,
        Chevron,
        img: ImageComponent,
    }
);

// (Router) Title is required and used for (Browser) title
// in a format of "${title} - ${appName}". In order to show
// the application name only, it needs to be an empty value.
// const routesWithTitle = filterCapabilityRoutes(capabilitiesConfig, routes);
const routesWithoutTitle = routes.map((route) => {
    return {
        ...route,
        title: ''
    };
});
let inactivityTimer = null;
let isExpired = false;

function App() {
    const appVersion = '1.1.200';

    const auth = useAuthentication();
    const translator = useContext(TranslatorContext);
    const [viewModelService, setViewModelService] = useState(undefined);
    const [showLeftNav, setshowLeftNav] = useState(true);
    const [aodaPageTitle, setAodaPageTitle] = useState(translator(messages.claimsPageTitle));

    const setDecodedEncodedKeywords = useCallback((value) => {
        localStorage.setItem('setDecodedEncodedKeywords', value);
    }, []);

    const setSpecialCharReplaceWithUniCodeKeywords = useCallback((value) => {
        localStorage.setItem('setSpecialCharReplaceWithUniCodeKeywords', value);
    }, []);

    const getDecodedEncodedKeywords = useCallback(() => {
        return localStorage.getItem('setDecodedEncodedKeywords');
    }, []);

    const getSpecialCharReplaceWithUniCodeKeywords = useCallback(() => {
        return localStorage.getItem('setSpecialCharReplaceWithUniCodeKeywords');
    }, []);

    const userSettings = {
        setDecodedEncodedKeywords,
        setSpecialCharReplaceWithUniCodeKeywords,
        getDecodedEncodedKeywords,
        getSpecialCharReplaceWithUniCodeKeywords
    };


    useEffect(() => {
        const translatorFn = vmTranslator(translator);
        import(
            /* webpackChunkName: "product-metadata" */
            // eslint-disable-next-line import/no-unresolved
            'product-metadata'
        ).then((productMetadata) => {
            const { default: result } = productMetadata;
            setViewModelService(
                ViewModelServiceFactory.getViewModelService(result, translatorFn)
            );
        });
    }, [translator]);

    useEffect(() => {
        console.log(`OSPC App version: ${appVersion}`);
        const currentLang = LocaleService.getStoredLocale();
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const lang = params.get('lang');
        const action = params.get('action');
        if (lang) {
            if (lang === 'fr' && lang !== currentLang) {
                LocaleService.setCurrentLocale(lang);
                LocaleService.setCurrentLanguage(lang);
                window.location.reload();
            }
            if (lang === 'en' && lang !== currentLang) {
                LocaleService.setCurrentLocale(lang);
                LocaleService.setCurrentLanguage(lang);
                window.location.reload();
            }
        } else if (currentLang) {
            const currentLangStr = currentLang.substring(0, 2);
            if (currentLangStr === 'en' || currentLangStr === 'fr') {
                LocaleService.setCurrentLocale(currentLangStr);
                LocaleService.setCurrentLanguage(currentLangStr);
            }
        } else {
            LocaleService.setCurrentLocale('en');
            LocaleService.setCurrentLanguage('en');
        }

        if (action === 'CreateProfile') {
            sessionStorage.setItem('isCreateProfile', 'true');
        } 
    }, [LocaleService]);

    const showLeftNavfn = () => {
        console.log(window.location.pathname);
        if ((['/account-management/claims', '/account-management/addclaim',
            '/account-management/session-expired'].indexOf(window.location.pathname) !== -1)
            || (window.location.pathname.indexOf('/account-management/enrollmentconfirmation') !== -1)
            || (window.location.pathname.indexOf('/account-management/directdeposit') !== -1)
            || (window.location.pathname.indexOf('/account-management/claim-intake') !== -1)
            || (window.location.pathname.indexOf('/account-management/reportOverview') !== -1)
            || (window.location.pathname.indexOf('/account-management/noPinDirectdeposit') !== -1)
            || (window.location.pathname.indexOf('/account-management/unlockDirectDeposit') !== -1)) {
            setshowLeftNav(true);
        } else {
            setshowLeftNav(false);
        }
    };

    /* hide left nav component if current screen is not home screen */
    useEffect(() => {
        let defaultScreen = 0;
        const elementRef = document.getElementById('pageTitle');
        if ('/account-management/session-expired'.indexOf(window.location.pathname) !== -1) {
            isExpired = true;
            inactivityTimer = null;
        } else {
            isExpired = false;
        }
        if (window.location.pathname.includes('/account-management/claims')) {
            defaultScreen = 0;
            document.title = translator(messages.claimsPageTitle);
            setAodaPageTitle(translator(messages.claimsPageTitle));
        } else if (window.location.pathname.includes('/account-management/addclaim')) {
            defaultScreen = 0;
            document.title = translator(messages.addClaimPageTitle);
            setAodaPageTitle(translator(messages.addClaimPageTitle));
        } else if (window.location.pathname.includes('/account-management/enrollmentconfirmation')) {
            defaultScreen = 0;
            document.title = translator(messages.claimConfirmationPageTitle);
            setAodaPageTitle(translator(messages.claimConfirmationPageTitle));
        } else if (window.location.pathname.includes('/account-management/claimOverview')) {
            defaultScreen = 0;
            document.title = translator(messages.claimOverviewPageTitle);
            setAodaPageTitle(translator(messages.claimOverviewPageTitle));
        } else if (window.location.pathname.includes('/account-management/benefitPayment')) {
            defaultScreen = 0;
            document.title = translator(messages.benefitPaymentPageTitle);
            setAodaPageTitle(translator(messages.benefitPaymentPageTitle));
        } else if (window.location.pathname.includes('/account-management/healthCare')) {
            defaultScreen = 0;
            document.title = translator(messages.healthCarePageTitle);
            setAodaPageTitle(translator(messages.healthCarePageTitle));
        } else if (window.location.pathname.includes('/account-management/nonHealthCare')) {
            defaultScreen = 0;
            document.title = translator(messages.nonHealthCarePageTitle);
            setAodaPageTitle(translator(messages.nonHealthCarePageTitle));
        } else if (window.location.pathname.includes('/account-management/appeals')) {
            defaultScreen = 0;
            document.title = translator(messages.appealsPageTitle);
            setAodaPageTitle(translator(messages.appealsPageTitle));
        } else if (window.location.pathname.includes('/account-management/documentUpload')) {
            defaultScreen = 0;
            document.title = translator(messages.claimDocumentsPageTitle);
            setAodaPageTitle(translator(messages.claimDocumentsPageTitle));
        } else if (window.location.pathname.includes('/account-management/documentHistory')) {
            defaultScreen = 0;
            document.title = translator(messages.documentHistoryPageTitle);
            setAodaPageTitle(translator(messages.documentHistoryPageTitle));
        } else if (window.location.pathname.includes('/account-management/directdeposit')) {
            defaultScreen = 0;
            document.title = translator(messages.claimDirectdepositPageTitle);
            setAodaPageTitle(translator(messages.claimDirectdepositPageTitle));
        } else if (window.location.pathname.includes('/account-management/sendmessage')) {
            defaultScreen = 0;
            document.title = translator(messages.sendUsMessagePageTitle);
            setAodaPageTitle(translator(messages.sendUsMessagePageTitle));
        } else if (window.location.pathname.includes('/account-management/mail')) {
            defaultScreen = 0;
            setAodaPageTitle(translator(messages.mailPageTitle));
            document.title = translator(messages.mailPageTitle);
        } else if (window.location.pathname.includes('/account-management/helpful-link')) {
            defaultScreen = 0;
            document.title = translator(messages.helpFullLinkPageTitle);
            setAodaPageTitle(translator(messages.helpFullLinkPageTitle));
        } else {
            defaultScreen = 1;
            document.title = translator(messages.defaultPageTitle);
            setAodaPageTitle(translator(messages.defaultPageTitle));
        }
        if (elementRef) {
            elementRef.focus();
        }
        if (defaultScreen === 0) {
            window.scrollTo(0, 0);
        }
        showLeftNavfn();
    }, [window.location.pathname]);

    const resetSessionTimer = () => {
        if (inactivityTimer !== null) {
            inactivityTimer.resetInactivityTimer();
        }
    };
    useEffect(() => {
        document.addEventListener('click', resetSessionTimer);
        document.addEventListener('mousedown', resetSessionTimer);
        document.addEventListener('touchstart', resetSessionTimer);
        document.addEventListener('touchmove', resetSessionTimer);
        document.addEventListener('keydown', resetSessionTimer);

        // jutro upgrade check
        // SE-1280, SE-1283 (DI-11) remove jutro skipNav link element
        // check if new jutro version updates the blow css style class name
        const skipcomponent = document.querySelector('.jut__SkipNav__skipNav');
        if (skipcomponent) {
            skipcomponent.remove();
        }

        return () => {
            document.removeEventListener('click', resetSessionTimer);
            document.removeEventListener('mousedown', resetSessionTimer);
            document.removeEventListener('touchstart', resetSessionTimer);
            document.removeEventListener('touchmove', resetSessionTimer);
            document.removeEventListener('keydown', resetSessionTimer);
        };
    }, []);

    const handleError = useCallback(() => {
        // ospcchange the below dialog does not show when unhandled error happens, need more investigation
        // it is better to log the exception to appliation insight and console log, and then redirect ui to system error page instead
        // of show an error dialog.
        return ModalNextProvider.showAlert({
            title: messages.error,
            message: messages.errorMessage,
            status: 'error',
            icon: 'mi-error-outline',
            confirmButtonText: commonMessages.ok
        }).catch(_.noop);
    }, []);

    if (auth.isLoggedIn && !isExpired) {
        inactivityTimer = SessionTimeoutService();
        inactivityTimer.resetInactivityTimer();
    }

    // AODA Skip to main content function
    const skipLink = (e) => {
        e.preventDefault();
        const wsibNodes = document.querySelectorAll('.wsib_ospcMainContainer');
        const intakeNodes = document.querySelectorAll('.bodyContainer');
        const nodes = document.querySelectorAll('.osbcMainContainer');
        const noHashURL = window.location.href.replace(/#.*$/, '');
        window.history.replaceState('', document.title, noHashURL);
        if (!e.key || e.key === 'Enter') {
            if (wsibNodes.length > 0) {
                if (wsibNodes[0].id) {
                    document.location.hash = wsibNodes[0].id;
                }
            } else if (nodes.length > 0) {
                if (nodes[0].id) {
                    document.location.hash = nodes[0].id;
                }
            } else if (intakeNodes.length > 0) {
                if (intakeNodes[0].id) {
                    document.location.hash = intakeNodes[0].id;
                }
            }
        }
        return false;
    };


    return (
        <Suspense fallback={<p id="pageTitle" className="pageTittle">{aodaPageTitle}</p>
        }
        >
            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}>
                <AppContext.Provider value={userSettings}>
                    <React.Fragment> 
                        <AccurateBreakpointPropagation>
                            <DependencyProvider
                                value={{
                                    SessionTimeoutService: SessionTimeoutService
                                }}
                            >
                                <ErrorBoundary onError={handleError}>
                                    <ViewModelServiceContext.Provider value={viewModelService}>
                                        <ModalNextProvider />
                                        <Switch>
                                            {(appConfig.env.DEPLOYMENT_HOSTNAME === 'localhost') && <Route exact path="/login-page" component={EntryPage} />}
                                            {(appConfig.env.DEPLOYMENT_HOSTNAME !== 'localhost') && <Route exact path="/login-page" component={Login} />}
                                            <Route exact path="/session-expired" component={SessionExpiredHome} />
                                            <Route path="/idp-system-error-home" component={IdpSystemErrorHome} />
                                            <Route path="/system-error" component={SystemErrorHome} />
                                            <Route path="/ospcLogout" component={Logout} />
                                            <Route path="/ospc/uaa/login/callback/microsoftb2c" component={IdpCallbackComponent} />
                                            <DynamicRoute path="/">
                                                <GrantedAuthorities
                                                    checkRenderBasedOnAuthorities={shouldRouteToSignUp}
                                                >

                                                    <Header />
                                                    {
                                                        (showLeftNav) ? (
                                                            <Main>
                                                                <NavRoutes
                                                                    resolveComponentMap={componentMap}
                                                                    routes={routesWithoutTitle}
                                                                />
                                                            </Main>

                                                        ) : (
                                                            <Main>
                                                                <aside tabIndex="-1" id="bodySection" className="osbcMainContainer"><ClaimTombstone /></aside>
                                                                <div id="wsib_ospcBodySection" className="wsib_ospcMainContainer leftNavMainClass">
                                                                    <LeftNav />
                                                                    <NavRoutes
                                                                        resolveComponentMap={componentMap}
                                                                        routes={routesWithoutTitle}
                                                                    />
                                                                </div>
                                                            </Main>
                                                        )
                                                    }
                                                    <Footer />
                                                </GrantedAuthorities>
                                            </DynamicRoute>
                                        </Switch>
                                    </ViewModelServiceContext.Provider>
                                </ErrorBoundary>
                            </DependencyProvider>
                        </AccurateBreakpointPropagation>
                    </React.Fragment>
                </AppContext.Provider>
            </GoogleReCaptchaProvider>
        </Suspense>

    );
}

export default withAuthContextProvider()(withAITracking(reactPlugin, App));
