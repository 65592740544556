import { defineMessages } from 'react-intl';

export default defineMessages({
    myclaims: {
        id: 'header.Business Home',
        defaultMessage: 'My claims'
    },
    myProfile: {
        id: 'header.My Profile',
        defaultMessage: 'My profile'
    },
    logout: {
        id: 'header.logout',
        defaultMessage: 'Logout'
    },
    onlineServices: {
        id: 'header.Online Services',
        defaultMessage: 'Online Services'
    },
    french: {
        id: 'header.French',
        defaultMessage: 'Français'
    },
    menu: {
        id: 'header.modal.Menu',
        defaultMessage: 'Menu'
    },
    iAm: {
        id: 'header.I am Label',
        defaultMessage: 'I am: '
    },
    aBusiness: {
        id: 'header.iAmDropdown.input.Business',
        defaultMessage: 'a business'
    },
    wsibLogoTitle: {
        id: 'header.wsibLogoTitle',
        defaultMessage: 'Home. Opens in a new tab.'
    },
    injuredOrIll: {
        id: 'header.iAmDropdown.input.Injured or Ill',
        defaultMessage: 'Injured or Ill'
    },
    username: {
        id: 'header.username',
        defaultMessage: 'hello, '
    },
    exit: {
        id: 'header.exit',
        defaultMessage: 'Exit'
    },
    claimInformation: {
        id: 'header.claimInfo',
        defaultMessage: 'Claim information',
    },
    documents: {
        id: 'header.documents',
        defaultMessage: 'Documents',
    },
    messages: {
        id: 'header.messages',
        defaultMessage: 'Messages',
    },
    resources: {
        id: 'header.resources',
        defaultMessage: 'Resources',
    },
    claimOverview: {
        id: 'header.claimOverview',
        defaultMessage: 'Claim overview',
    },
    benefitPayment: {
        id: 'header.benefitPayment',
        defaultMessage: 'Benefit payments',
    },
    healthCareBenefits: {
        id: 'header.healthCareBenefits',
        defaultMessage: 'Health care benefits',
    },
    nonHealthCareBenefits: {
        id: 'header.nonHealthCareBenefits',
        defaultMessage: 'Non-Health care benefits',
    },
    appeals: {
        id: 'header.appeals',
        defaultMessage: 'Appeals',
    },
    claimDocuments: {
        id: 'header.claimDocuments',
        defaultMessage: 'Claim documents',
    },
    documentHistory: {
        id: 'header.documentHistory',
        defaultMessage: 'Submitted documents',
    },
    sendUsMessage: {
        id: 'header.sendUsMessage',
        defaultMessage: 'Send us a message',
    },
    mail: {
        id: 'header.mail',
        defaultMessage: 'Mail',
    },
    helpfulLink: {
        id: 'header.helpfulLink',
        defaultMessage: 'Helpful links',
    },
    faq: {
        id: 'header.faq',
        defaultMessage: 'Frequently asked questions',
    },
    YouSure: {
        id: 'claim-intake.header.YouSure',
        defaultMessage: 'Are you sure you want to leave this page?',
    },
    YouSureNoRef: {
        id: 'claim-intake.header.YouSureNoRef',
        defaultMessage: 'Are you sure?',
    },
    ChangeReportType: {
        id: 'claim-intake.header.ChangeReportType',
        defaultMessage: 'All your progress will be lost.',
    },
    ChangeReportingNoRef: {
        id: 'claim-intake.header.ChangeReportingNoRef',
        defaultMessage: 'Are you sure you want to exit? Your report will be deleted.',
    },
    Cancel: {
        id: 'claim-intake.header.Cancel',
        defaultMessage: 'Stay on this page',
    },
    CancelNoRef: {
        id: 'claim-intake.header.NoRef',
        defaultMessage: 'No',
    },
    Confirm: {
        id: 'claim-intake.header.Confirm',
        defaultMessage: 'Leave this page',
    },
    ConfirmNoRef: {
        id: 'claim-intake.header.ConfirmNoRef',
        defaultMessage: 'Yes',
    },
    YouSureChangeLanguage: {
        id: 'claim-intake.header.YouSureChangeLanguage',
        defaultMessage: 'Are you sure?',
    },
    ChangeLanguage: {
        id: 'claim-intake.header.ChangeLanguage',
        defaultMessage: 'Are you sure you want to change the language? All your progress will be lost.'
    },
});
